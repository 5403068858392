body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* styles.css */
body.light-theme {
  background-color: #ffffff;
  color: #333333;
}

body.dark-theme {
  background-color: #333333;
  color: #ffffff;
}

.dark-theme .button {
  background-color: #4a4a4a;
  color: #ffffff;
}

.light-theme .button {
  background-color: #f0f0f0;
  color: #333333;
}